import React, { Component } from "react";
import "./PasswordForgot.css";
import { Settings, Version } from "../../Settings";
import "../../../node_modules/material-design-lite/material.min.css";
import "material-design-lite/material.min";
import NotFound from "../NotFound/NotFound";
import Header from "../../components/Header/Header";
import Button from "../../components/Button";
import LoadingCard from "../../components/LoadingCard";

class PasswordForgot extends Component {
  state = {
    username: "",
    sendTo: false,
    Settings,
    Version,
    status: 0,
    pending: false,
  };

  componentWillMount() {
    let url = this.state.Settings.url;
    fetch(url + "/users/me", {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/ld+json",
      }),
    })
      .then((response) => {
        this.setState({
          status: 200,
        });
      })
      .catch((error) => {
        this.setState({
          status: 404,
        });
      });
  }

  handleChange = (event) => {
    const username = event.target.value.toLowerCase();
    this.setState({ username });
  };

  handleSubmit = (event) => {
    if (this.state.pending !== true) {
      event.preventDefault();
      this.setState({ sendTo: true, pending: true });
      let data = { username: this.state.username };
      let url = this.state.Settings.url;
      // Get lien
      fetch(url + "/users/password/forgot", {
        method: "POST",
        body: JSON.stringify(data),
        headers: new Headers({
          "Content-Type": "application/ld+json",
        }),
      })
        .then((response) => response.json())
        .then((result) => {
          this.setState({ pending: false });
          if (result.hasOwnProperty("parameters")) {
            window.localStorage.setItem(
              "successPasswordForgot",
              JSON.stringify(result.parameters)
            );
          }
          window.location.replace(
            "/" + this.state.Version.url + "/password-forgot-send"
          );
        });
    }
  };

  render() {
    if (this.state.status === 404) {
      return <NotFound />;
    }
    if (this.state.status === 200) {
      return (
        <div className="master-vd">
          <div className="card-vd mdl-card mdl-shadow--2dp">
            <Header subtitle="Récupération du mot de passe" />
            <div className="mdl-card__supporting-text">
              <h5>Renseignez votre e-mail</h5>
              <span>
                Veuillez renseigner l'adresse e-mail de votre compte Bookinou,
                pour pouvoir changer votre mot de passe.
              </span>
              <form id="join-form-vd" onSubmit={this.handleSubmit}>
                <div className="mdl-textfield mdl-js-textfield">
                  <input
                    className="mdl-textfield__input"
                    value={this.state.username}
                    onChange={this.handleChange}
                    type="email"
                    required
                  />
                  <label className="mdl-textfield__label" htmlFor="username">
                    {" "}
                    {this.state.username === "" ? "Votre adresse e-mail" : ""}
                  </label>
                </div>
                <div className="button-vd mdl-card__actions mdl-card--border">
                  <Button
                    label="SUIVANT"
                    type="submit"
                    isLoading={this.state.pending}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      );
    }

    if (this.state.status === 0) {
      return (
        <LoadingCard
          subtitle="Récupération du mot de passe"
          description="Chargement"
        />
      );
    }
  }
}

export default PasswordForgot;
