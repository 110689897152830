import React, { Component } from "react";
import "./ChangePassword.css";
import { Settings, Version } from "../../Settings";
import "../../../node_modules/material-design-lite/material.min.css";
import "material-design-lite/material.min";
import Swal from "sweetalert2";
import NotFound from "../../pages/NotFound/NotFound";
import LoadingCard from "../../components/LoadingCard";
import Header from "../../components/Header/Header";
import Button from "../../components/Button";

class ChangePassword extends Component {
  state = {
    token: this.props.match.params.token,
    passwordCurrent: "",
    password: "",
    confirmation: "",
    sendTo: false,
    username: "",
    errorMessage: "",
    Settings,
    showmessage: "",
    img: "../../img/Warning.ico",
    Version,
    status: 0,
    pending: false,
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  componentWillMount() {
    let url = this.state.Settings.url;
    fetch(url + "/users/me", {
      headers: new Headers({
        Authorization: "Bearer " + this.state.token,
        "Content-Type": "application/ld+json",
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.hasOwnProperty("username")) {
          this.setState({
            username: result.username,
          });
        } else {
          this.setState({
            username: "",
            errorMessage:
              "Cette page est invalide. Veuillez refaire une demande de changement de mot de passe.",
          });
        }
        this.setState({
          status: 200,
        });
      })
      .catch((error) => {
        this.setState({
          status: 404,
        });
      });
  }

  handleSubmit = (event) => {
    if (this.state.pending !== true) {
      event.preventDefault();
      this.setState({ sendTo: true, pending: true });
      let data = {
        username: this.state.username,
        password: this.state.passwordCurrent,
      };
      let url = this.state.Settings.url;
      fetch(url + "/users/login", {
        method: "POST",
        body: JSON.stringify(data),
        headers: new Headers({
          "Content-Type": "application/ld+json",
        }),
      })
        .then((response) => response.json())
        .then((result) => {
          if (result.hasOwnProperty("error")) {
            if (result.error.code === 400) {
              this.setState({
                errorMessage:
                  "Cette page est invalide. Veuillez refaire une demande de changement de mot de passe.",
                pending: false,
              });
            } else {
              this.setState({
                password: "",
                confirmation: "",
                passwordCurrent: "",
                errorMessage: "Votre mot de passe actuel est incorrect.",
                pending: false,
              });
            }
          } else {
            if (this.state.password === this.state.confirmation) {
              let data = {
                username: this.state.username,
                password: this.state.password,
                confirmation: this.state.confirmation,
                token: this.state.token,
              };
              fetch(url + "/users/password/reset", {
                method: "POST",
                body: JSON.stringify(data),
                headers: new Headers({
                  Authorization: "Bearer " + this.state.token,
                  "Content-Type": "application/ld+json",
                }),
              })
                .then((response) => response.json())
                .then((result) => {
                  if (result.status === "success") {
                    this.setState({
                      status: 0,
                    });
                    if (result.hasOwnProperty("parameters")) {
                      window.localStorage.setItem(
                        "successPasswordReset",
                        JSON.stringify(result.parameters)
                      );
                    }
                    window.location.replace(
                      "/" + this.state.Version.url + "/password-reset-success"
                    );
                  }
                  this.setState({
                    password: "",
                    confirmation: "",
                    passwordCurrent: "",
                    errorMessage: result.parameters.description,
                    pending: false,
                  });
                });
            } else {
              this.setState({
                password: "",
                confirmation: "",
                passwordCurrent: "",
                errorMessage: "Vos deux mots de passe ne correspondent pas.",
                pending: false,
              });
            }
          }
        });
    }
  };

  handAlert() {
    if (this.state.errorMessage !== "") {
      Swal.fire({
        text: this.state.errorMessage,
        imageWidth: 95,
        animation: false,
        imageUrl: "../../img/bad-logo.png",
        confirmButtonText: "Je corrige",
      });
      this.setState({
        showmessage: this.state.errorMessage,
        errorMessage: "",
      });
    }
  }

  render() {
    if (this.state.status === 200) {
      if (
        this.state.showmessage ===
          "Cette page est invalide. Veuillez refaire une demande de changement de mot de passe." ||
        this.state.username === ""
      ) {
        return (
          <div className="master-vd">
            <div className="card-vd mdl-card mdl-shadow--2dp">
              <Header subtitle="Réinitialisation de votre mot de passe" />
              <div className="mdl-card__supporting-text">
                <h5>Page invalide</h5>
                <div className="alert">{this.handAlert()}</div>
                <span className="error">
                  <strong>{this.state.showmessage}</strong>
                </span>
              </div>
            </div>
          </div>
        );
      } else {
        return (
          <div className="master-vd">
            <div className="card-vd mdl-card mdl-shadow--2dp">
              <Header subtitle="Changement du mot de passe" />
              <div className="mdl-card__supporting-text">
                <h5>Changez votre mot de passe</h5>
                <span>
                  Veuillez renseignez votre mot de passe actuel ci-dessous, puis
                  indiquer votre nouveau mot de passe en le confirmant 2 fois.{" "}
                </span>
                <br />
                {this.handAlert()}
                <span className="error">
                  <strong>{this.state.showmessage}</strong>
                </span>
                <form id="join-form-vd" onSubmit={this.handleSubmit}>
                  <div className="mdl-textfield mdl-js-textfield">
                    <input
                      className="mdl-textfield__input"
                      type="password"
                      name="passwordCurrent"
                      value={this.state.passwordCurrent}
                      onChange={this.handleChange}
                      required
                    />
                    <label
                      className="mdl-textfield__label"
                      htmlFor="passwordCurrent"
                    >
                      {this.state.passwordCurrent === ""
                        ? "Votre mot de passe actuel"
                        : ""}
                    </label>
                  </div>
                  <div className="mdl-textfield mdl-js-textfield">
                    <input
                      className="mdl-textfield__input"
                      type="password"
                      name="password"
                      value={this.state.password}
                      onChange={this.handleChange}
                      required
                    />
                    <label className="mdl-textfield__label" htmlFor="password">
                      {" "}
                      {this.state.password === ""
                        ? "Votre nouveau mot de passe"
                        : ""}
                    </label>
                  </div>
                  <div className="mdl-textfield mdl-js-textfield">
                    <input
                      className="mdl-textfield__input"
                      type="password"
                      name="confirmation"
                      value={this.state.confirmation}
                      onChange={this.handleChange}
                      required
                    />
                    <label
                      className="mdl-textfield__label"
                      htmlFor="confirmation"
                    >
                      {this.state.confirmation === ""
                        ? "Confirmez     votre nouveau mot de passe"
                        : ""}
                    </label>
                  </div>
                  <div className="button-vd mdl-card__actions mdl-card--border">
                    <Button
                      label="Suivant"
                      type="submit"
                      isLoading={this.state.pending}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        );
      }
    }
    if (this.state.status === 404) {
      return <NotFound />;
    }
    if (this.state.status === 0) {
      return (
        <LoadingCard
          subtitle="Changement du mot de passe"
          description="Chargement"
        />
      );
    }
  }
}

export default ChangePassword;
