import React from "react";
import "./Header.css";

const Header = ({ title, subtitle }) => {
  return (
    <section id="header-container">
      <div id="img-container">
        <img src="/img/emoji-boucle.png" alt="boucle-dor" id="emoji-boucle" />
        <img src="/img/logotype.png" alt="logotype-bookinou" id="logotype" />
        <img src="/img/bookinou.png" alt="bookinou" id="bookinou" />
      </div>
      <div className="text-container">
        <span id="title">{title || ""}</span>
        <span id="subtitle">{subtitle || ""}</span>
      </div>
    </section>
  );
};

export default Header;
