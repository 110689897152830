import React, { Component } from "react";
import "./StoryCard.css";

class StoryCard extends Component {
  state = {
    isClick: false,
  };

  handleClick = () => {
    this.setState({
      isClick: !this.state.isClick,
    });
  };

  render() {
    const { details, titreStory, add, image } = this.props;
    return (
      <div className="mdl-cell mdl-cell--4-col">
        <div
          className="demo-card-wide mdl-card mdl-shadow--2dp"
          onClick={() => (this.handleClick(), add(details))}
          style={{
            background: "url(" + image + ")",
          }}
        >
          <div className="mdl-card__title">
            <h2 className="mdl-card__title-text story-title">{titreStory}</h2>
          </div>
          <div
            className="mdl-card__actions mdl-card--border"
            style={{
              "background-color": this.state.isClick ? "#f15249" : "white",
            }}
          >
            <a
              className="mdl-button mdl-button--colored mdl-js-button mdl-js-ripple-effect"
              style={{ color: this.state.isClick ? "white" : "#3f51b5" }}
            >
              {this.state.isClick ? "SÉLECTIONNÉE" : "SÉLECTIONNER"}
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default StoryCard;
