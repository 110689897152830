import React, {Component} from "react";
import "./Login.css";
import {NavLink} from "react-router-dom";
import {Settings, Version} from "../../Settings";
import "../../../node_modules/material-design-lite/material.min.css";
import "material-design-lite/material.min";
import Swal from "sweetalert2";
import NotFound from "../NotFound/NotFound";
import Header from "../../components/Header/Header";
import Button from "../../components/Button";
import LoadingCard from "../../components/LoadingCard";
import version from "../../Settings/Version";
import RoleSelect from "../../components/RoleSelect";

class Login extends Component {
    state = {
        username: "",
        password: "",
        response: "",
        userToken: "",
        user: [],
        account: [],
        invitationToken: this.props.match.params.token,
        error: "",
        Settings,
        showError: "",
        Version,
        status: 0,
        statusCheck: 0,
        pending: false,
        roles: [],
        selectedRole: "",
        isRoleCertified: false,
        shouldBeCertified: false
    };

    componentWillMount() {
        let url = this.state.Settings.url;
        let data = this.state;
        fetch(url + "/users/me", {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/ld+json",
            }),
        })
            .then((response) => {
                fetch(url + "/invitation/join/" + this.state.invitationToken, {
                    method: "POST",
                    body: JSON.stringify(data),
                    headers: new Headers({
                        "Content-Type": "application/ld+json",
                    }),
                })
                    .then((response) => response.json())
                    .then((result) => {
                        if (result.hasOwnProperty("invitation")) {
                            this.setState({
                                user: result.invitation.user,
                                account: result.invitation.account,
                                statusCheck: 200,
                            });
                        } else {
                            this.setState({
                                error:
                                    "Cette page est invalide. Veuillez redemander une invitation.",
                                statusCheck: 404,
                            });
                        }
                    });
                this.setState({
                    status: 200,
                });

                if (version.url !== "v3.1") {
                    fetch(`${url}/labels?key=ROLE&i18n=fr_FR`)
                        .then((res) => res.json())
                        .then((result) => {
                            this.setState({
                                roles: result["hydra:member"],
                            });
                        });
                }
            })
            .catch((error) => {
                this.setState({
                    status: 404,
                });
            });
    }

    handleChange = (event) => {
        const {name, value} = event.target;
        if ([name][0] === "username") {
            this.setState({[name]: value.toLowerCase()});
        } else {
            this.setState({[name]: value});
        }
    };

    handleRoleChange = (newRole) => {
        this.setState({shouldBeCertified: false})

        if (['École', 'Médiathèque'].includes(this.state.selectedRole)) {
            this.setState({shouldBeCertified: true})
        }
        this.setState({
            selectedRole: newRole,
        });
    };

    handleSubmit = (event) => {
        if (this.state.pending !== true) {
            event.preventDefault();
            this.setState({sendTo: true, pending: true});
            let data = {
                username: this.state.username,
                password: this.state.password,
            };
            let url = this.state.Settings.url;
            fetch(url + "/users/login", {
                method: "POST",
                body: JSON.stringify(data),
                headers: new Headers({
                    "Content-Type": "application/ld+json",
                }),
            })
                .then((response) => response.json())
                .then((result) => {
                    if (result.error !== undefined) {
                        this.setState({
                            error:
                                "Votre adresse e-mail ou votre mot de passe est incorrect. Veuillez réessayer.",
                            password: "",
                            pending: false,
                        });
                    } else {
                        this.setState({
                            userToken: result["hydra:description"],
                        });

                        fetch(url + "/invitation/join/link/" + this.state.invitationToken, {
                            method: "POST",
                            body: "{}",
                            headers: new Headers({
                                Authorization: "Bearer " + this.state.userToken,
                                "Content-Type": "application/ld+json",
                            }),
                        })
                            .then((response) => response.json())
                            .then((result) => {
                                if (result["status"] === "error") {
                                    this.setState({
                                        error: result.parameters.description,
                                        password: "",
                                        pending: false,
                                    });
                                } else {
                                    if (
                                        version.url !== "v3.1" &&
                                        result.parameters?.id_membership
                                    ) {
                                        fetch(
                                            `${url}/memberships/${result.parameters?.id_membership}`,
                                            {
                                                method: "PUT",
                                                headers: {
                                                    Authorization: `Bearer ${this.state.userToken}`,
                                                    "Content-Type": "application/json",
                                                },
                                                body: JSON.stringify({
                                                    role: this.state.selectedRole,
                                                    isRoleCertified: this.state.isRoleCertified
                                                }),
                                            }
                                        )
                                            .then((response) => response.json())
                                            .then(() => {
                                                this.setState({pending: false});
                                                let data = {
                                                    user: this.state.user,
                                                    account: this.state.account,
                                                };
                                                window.localStorage.setItem(
                                                    "accountInvitation",
                                                    JSON.stringify(data)
                                                );
                                                return window.location.replace(
                                                    "/" + Version.url + "/login/success"
                                                );
                                            });
                                    }
                                    if (version.url === "v3.1") {
                                        this.setState({pending: false});
                                        let data = {
                                            user: this.state.user,
                                            account: this.state.account,
                                        };
                                        window.localStorage.setItem(
                                            "accountInvitation",
                                            JSON.stringify(data)
                                        );
                                        return window.location.replace(
                                            "/" + Version.url + "/login/success"
                                        );
                                    }
                                    // Temporary deactivation of the stories page.
                                    // fetch(url + "/graphql", {
                                    //     method: 'POST',
                                    //     body: JSON.stringify({ 'query': '{ stories { edges { node { id title picture { contentUrl } } }  pageInfo{ hasNextPage endCursor } totalCount } }' }),
                                    //     headers: new Headers({
                                    //         'Authorization': 'Bearer ' + this.state.userToken,
                                    //         'Content-Type': 'application/json'
                                    //     })
                                    // }).then(response => response.json())
                                    //     .then(
                                    //         (resultat) => {
                                    //return window.location.replace('/' + Version.url + '/stories/success');
                                    // Temporary deactivation of the stories page.
                                    // if (resultat.hasOwnProperty('data')) {
                                    //     if (resultat.data.stories !== null) {
                                    //         if (resultat.data.stories.totalCount === 0) {
                                    //             return window.location.replace('/' + Version.url + '/stories/success');
                                    //         }

                                    //     }
                                    //     window.location.replace('/' + this.state.Version.url + '/stories/' + this.state.userToken + '/' + result.parameters.id_membership);
                                    // } else {
                                    //     window.location.replace('/' + this.state.Version.url + '/stories/' + this.state.userToken + '/' + result.parameters.id_membership);
                                    // }
                                    // });
                                }
                            })
                            .catch((error) => {
                            });
                    }
                });
        }
    };

    handAlert() {
        if (this.state.error !== "") {
            Swal.fire({
                text: this.state.error,
                imageWidth: 95,
                animation: false,
                imageUrl: "../../../img/bad-logo.png",
                confirmButtonText: "Je corrige",
            });
            this.setState({
                showError: this.state.error,
                error: "",
            });
        }
    }

    render() {
        if (this.state.status === 200) {
            const {user, account} = this.state;
            if (this.state.statusCheck === 200) {
                return (
                    <div className="master-vd">
                        <div className="card-vd mdl-card mdl-shadow--2dp">
                            <Header
                                title={`${user.first_name} ${user.last_name}`}
                                subtitle="vous invite"
                            />
                            <div className="mdl-card__supporting-text">
                                {this.handAlert()}
                                <h5>
                                    <strong>Connectez-vous</strong>
                                </h5>
                                Vous avez été invité par {user.first_name} {user.last_name} à
                                rejoindre sa bibliothèque "<strong>{account.name}</strong>"
                                <br/>
                                <span className="error">
                  <strong>{this.state.showError}</strong>
                </span>
                                <form id="join-form-vd" onSubmit={this.handleSubmit}>
                                    <div className="mdl-textfield mdl-js-textfield">
                                        <input
                                            className="mdl-textfield__input"
                                            type="email"
                                            value={this.state.username}
                                            onChange={this.handleChange}
                                            name="username"
                                            required
                                        />
                                        <label className="mdl-textfield__label" htmlFor="username">
                                            {this.state.username === "" ? "Votre adresse e-mail" : ""}
                                        </label>
                                    </div>
                                    <div className="mdl-textfield mdl-js-textfield">
                                        <input
                                            className="mdl-textfield__input"
                                            type="password"
                                            name="password"
                                            value={this.state.password}
                                            onChange={this.handleChange}
                                            required
                                        />
                                        <label className="mdl-textfield__label" htmlFor="password">
                                            {this.state.password === "" ? "Votre mot de passe" : ""}
                                        </label>
                                    </div>
                                    {this.state.roles && this.state.roles.length > 0 && (
                                        <RoleSelect
                                            roles={this.state.roles}
                                            selectedRole={this.state.selectedRole}
                                            handleRoleChange={this.handleRoleChange}
                                        />
                                    )}

                                    {['École', 'Médiathèque'].includes(this.state.selectedRole) && (
                                        <div className="mdl-textfield mdl-js-textfield">
                                            <label className="mdl-checkbox mdl-js-checkbox" htmlFor="school-checkbox">
                                                <input type="checkbox" id="school-checkbox"
                                                       className="mdl-checkbox__input"
                                                       required={true}
                                                       onClick={() => {
                                                           this.setState({isRoleCertified: !this.state.isRoleCertified})
                                                       }} checked={this.state.isCertified}/>
                                                <span className="mdl-checkbox__label">Je certifie sur l'honneur que je représente bien {this.state.selectedRole === "École" ? ' une école ou une classe.' : ' une médiathèque'} </span>
                                            </label>
                                        </div>
                                    )}

                                    <hr/>
                                    <div id="button-container">
                                        <NavLink
                                            style={{
                                                backgroundColor: "#00818d",
                                                textDecoration: "none",
                                                color: "white",
                                                fontSize: "15px",
                                                padding: "5px 15px",
                                                marginTop: 0,
                                                borderRadius: "2px",
                                                fontFamily: "Quicksand",
                                            }}
                                            exact
                                            to={
                                                "/" +
                                                this.state.Version.url +
                                                "/invitation-join/" +
                                                this.state.invitationToken
                                            }
                                        >
                                            S'INSCRIRE
                                        </NavLink>

                                        <Button
                                            label="SUIVANT"
                                            type="submit"
                                            isLoading={this.state.pending}
                                        />
                                    </div>
                                </form>
                                <div style={{margin: "2% 5%"}}>
                                    <NavLink
                                        style={{
                                            backgroundColor: "#00818d",
                                            textDecoration: "none",
                                            color: "white",
                                            fontSize: "15px",
                                            padding: "5px 15px",
                                            marginTop: 0,
                                            borderRadius: "2px",
                                            fontFamily: "Quicksand",
                                            float: "left",
                                        }}
                                        exact
                                        to={"/" + this.state.Version.url + "/password-forgot"}
                                    >
                                        MOT DE PASSE OUBLIÉ
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }
            if (this.state.statusCheck === 404) {
                return (
                    <div className="master-vd">
                        <div className="card-vd mdl-card mdl-shadow--2dp">
                            <Header title="Invitation"/>
                            <div className="mdl-card__supporting-text">
                                <div className="errorNotFound">
                                    <h5>Page invalide</h5>
                                    {this.handAlert()}
                                    <span className="error">
                    <strong> {this.state.showError} </strong>{" "}
                  </span>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }
            if (this.state.statusCheck === 0) {
                return (
                    <div className="master-vd">
                        <div className="card-vd mdl-card mdl-shadow--2dp">
                            <Header title="Invitation"/>
                            <div className="mdl-card__supporting-text">
                                <div className="errorNotFound">
                                    <h5>Chargement</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }
        }
        if (this.state.status === 404) {
            return <NotFound/>;
        }
        if (this.state.status === 0) {
            return <LoadingCard title="Invitation" description="Chargement"/>;
        }
    }
}

export default Login;
