import React, { useEffect, useState } from "react";
import "./Invitation.css";
import "../../../node_modules/material-design-lite/material.min.css";
import "material-design-lite/material.min";
import NotFound from "../NotFound/NotFound";
import Header from "../../components/Header/Header";
import Button from "../../components/Button";
import { Version } from "../../Settings";

const SuccessInvitation = () => {
  const [description, setDescription] = useState(null);
  const [isLoginRoute, setIsLoginRoute] = useState(false);

  useEffect(() => {
    const accountInvitation = window.localStorage.getItem("accountInvitation");
    setDescription(JSON.parse(accountInvitation));
    setIsLoginRoute(
      window.location.pathname === `/${Version.url}/login/success`
    );
    window.localStorage.clear();
  }, []);

  const redirectToApp = () => {
    window.location.href = "https://open.mybookinou.com";
  };

  return (
    <>
      {description !== null ? (
        <div className="master-vd">
          <div className="card-vd mdl-card" style={{ border: "none" }}>
            <Header
              title={`${description?.user?.first_name} ${description?.user?.last_name}`}
              subtitle="vous invite"
            />
            <div className="mdl-card__supporting-text card-body">
              <h5>Félicitations</h5>
              <span>
                Vous venez de rejoindre la bibliothèque "
                <strong>{description?.account?.name}</strong>". Rendez-vous sur
                l'application mobile pour découvrir toutes les histoires
                enregistrées et pour enregistrer vos propres histoires ! <br />
                Si vous n'avez pas encore l'application mobile, téléchargez-la
                sans plus attendre
              </span>
            </div>
            <div className="success-button-container">
              <Button
                label={
                  isLoginRoute ? "J'AI DÉJÀ L'APPLI" : "JE TÉLÉCHARGE L'APPLI"
                }
                fontSize="15px"
                onClickAction={redirectToApp}
              />
              <a
                className="secondary-button"
                href="https://open.mybookinou.com"
              >
                {isLoginRoute ? "Je télécharge l'appli" : "J'ai déjà l'appli"}
              </a>
            </div>
          </div>
        </div>
      ) : (
        <NotFound />
      )}
    </>
  );
};

export default SuccessInvitation;
