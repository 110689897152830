import React, { Component } from "react";
import "./PasswordForgotChange.css";
import { Settings, Version } from "../../Settings";
import "../../../node_modules/material-design-lite/material.min.css";
import "material-design-lite/material.min";
import { NavLink } from "react-router-dom";
import Swal from "sweetalert2";

import Header from "../../components/Header/Header";
import Button from "../../components/Button";

class PasswordForgotChange extends Component {
  state = {
    password: "",
    confirmation: "",
    token: this.props.match.params.token,
    response: "",
    Settings,
    showmessage: "",
    Version,
    status: 0,
    pending: false,
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handleSubmit = (event) => {
    if (this.state.pending !== true) {
      event.preventDefault();
      this.setState({ sendTo: true, pending: true });
      if (this.state.password === this.state.confirmation) {
        let data = {
          password: this.state.password,
          confirmation: this.state.confirmation,
        };
        let url = this.state.Settings.url;
        fetch(url + "/users/password/forgot/change/" + this.state.token, {
          method: "POST",
          body: JSON.stringify(data),
          headers: new Headers({
            "Content-Type": "application/ld+json",
          }),
        })
          .then((response) => response.json())
          .then((result) => {
            if (result.status === "error") {
              this.setState({
                password: "",
                confirmation: "",
                response: result.parameters.description,
                pending: false,
              });
            } else {
              if (result.hasOwnProperty("parameters")) {
                window.localStorage.setItem(
                  "successPasswordForgotChange",
                  JSON.stringify(result.parameters)
                );
              }
              this.setState({ pending: false });
              window.location.replace(
                "/" + this.state.Version.url + "/password-forgot-success"
              );
            }
          });
      } else {
        this.setState({
          password: "",
          confirmation: "",
          response: "Vos deux mots de passe ne correspondent pas.",
          pending: false,
        });
      }
    }
  };

  handAlert() {
    if (this.state.response !== "") {
      Swal.fire({
        text: this.state.response,
        imageWidth: 95,
        animation: false,
        imageUrl: "../../img/bad-logo.png",
        confirmButtonText: "Je corrige",
      });
      this.setState({
        showmessage: this.state.response,
        response: "",
      });
    }
  }

  render() {
    return (
      <div className="master-vd">
        <div className="card-vd mdl-card mdl-shadow--2dp">
          <Header subtitle="Récupération du mot de passe" />
          <div className="mdl-card__supporting-text">
            <h5>Choisissez votre nouveau mot de passe</h5>

            {this.handAlert()}
            <span className="error">
              <strong>{this.state.showmessage}</strong>
            </span>
            <form id="join-form-vd" onSubmit={this.handleSubmit}>
              <div className="mdl-textfield mdl-js-textfield">
                <input
                  className="mdl-textfield__input"
                  value={this.state.password}
                  type="password"
                  name="password"
                  onChange={this.handleChange}
                  required
                />
                <label className="mdl-textfield__label" htmlFor="password">
                  {this.state.password === ""
                    ? "Votre nouveau mot de passe"
                    : ""}
                </label>
              </div>
              <div className="mdl-textfield mdl-js-textfield">
                <input
                  className="mdl-textfield__input"
                  value={this.state.confirmation}
                  type="password"
                  name="confirmation"
                  onChange={this.handleChange}
                  required
                />
                <label className="mdl-textfield__label" htmlFor="password2">
                  {this.state.confirmation === ""
                    ? "Confirmer votre nouveau mot de passe"
                    : ""}
                </label>
              </div>
              <div className="button-container">
                <NavLink
                  style={{
                    backgroundColor: "#00818d",
                    textDecoration: "none",
                    color: "white",
                    fontSize: "15px",
                    padding: 5,
                    marginTop: 0,
                    borderRadius: "2px",
                    fontFamily: "Quicksand",
                  }}
                  exact
                  to={"/" + this.state.Version.url + "/password-forgot"}
                >
                  Annuler
                </NavLink>
                <Button
                  label="SUIVANT"
                  type="submit"
                  isLoading={this.state.pending}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default PasswordForgotChange;
