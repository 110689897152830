import React, {Component} from "react";
import "./Invitation.css";
import {Redirect, NavLink} from "react-router-dom";
import {Settings, Version} from "../../Settings";
import "../../../node_modules/material-design-lite/material.min.css";
import "../../../node_modules/material-design-lite/material.min.js";
import Swal from "sweetalert2";
import NotFound from "../NotFound/NotFound";
import Header from "../../components/Header/Header";
import Button from "../../components/Button";
import LoadingCard from "../../components/LoadingCard";
import version from "../../Settings/Version";
import RoleSelect from "../../components/RoleSelect";

class Invitation extends Component {
    state = {
        username: "",
        sendTo: false,
        user: null,
        account: null,
        firstName: "",
        lastName: "",
        password: "",
        confirmation: "",
        receiveOk: false,
        token: this.props.match.params.token,
        errorFetchUser: "",
        Settings,
        message: "",
        Version,
        status: 0,
        statusInvitation: 0,
        pending: false,
        roles: [],
        selectedRole: "",
        isCertified: false,
        shouldBeCertified: false
    };

    componentDidMount() {
        let data = this.state;
        let url = this.state.Settings.url;
        fetch(url + "/invitation/join/" + this.state.token, {
            method: "POST",
            body: JSON.stringify(data),
            headers: new Headers({
                "Content-Type": "application/ld+json",
            }),
        })
            .then((response) => response.json())
            .then((result) => {
                if (result.hasOwnProperty("invitation")) {
                    this.setState({
                        user: result.invitation.user,
                        account: result.invitation.account,
                        statusInvitation: 200,
                    });
                } else {
                    this.setState({
                        errorFetchUser:
                            "Cette page est invalide. Veuillez redemander une invitation.",
                        statusInvitation: 404,
                    });
                }
                this.setState({
                    status: 200,
                });
                if (version.url !== "v3.1") {
                    fetch(`${url}/labels?key=ROLE&i18n=fr_FR`)
                        .then((res) => res.json())
                        .then((result) => {
                            this.setState({
                                roles: result["hydra:member"],
                            });
                        });
                }
            })
            .catch((error) => {
                if (this.state.token === "undefined") {
                    this.setState({
                        errorFetchUser:
                            "Le compte a atteint son nombre de membres maximum.",
                        statusInvitation: 404,
                    });
                } else {
                    this.setState({
                        errorFetchUser:
                            "Cette page est invalide. Veuillez redemander une invitation.",
                        statusInvitation: 404,
                    });
                }
                this.setState({
                    status: 200,
                });
            });
    }

    handleChange = (event) => {
        const {name, value} = event.target;
        if ([name][0] === "username") {
            this.setState({[name]: value.toLowerCase()});
        } else {
            this.setState({[name]: value});
        }
    };

    handleRoleChange = (newRole) => {
        this.setState({shouldBeCertified: false})

        if (['École', 'Médiathèque'].includes(this.state.selectedRole)) {
            this.setState({shouldBeCertified: true})
        }
        this.setState({
            selectedRole: newRole,
        });
    };

    handleSubmit = (event) => {
        if (this.state.pending !== true) {
            event.preventDefault();
            this.setState({sendTo: true, pending: true});
            let messageError = "";
            let data = {
                username: this.state.username,
                password: this.state.password,
                token: this.state.token,
                firstName: this.state.firstName,
                lastName: this.state.lastName,
            };
            let url = this.state.Settings.url;
            if (this.state.password === this.state.confirmation) {
                // Get lien
                // Add user
                fetch(url + "/users", {
                    method: "POST",
                    body: JSON.stringify(data),
                    headers: new Headers({
                        "Content-Type": "application/ld+json",
                    }),
                })
                    .then((response) => response.json())
                    .then((result) => {
                        if (result.hasOwnProperty("violations")) {
                            if (
                                result.violations["0"].message.split("|")[0].trim() ===
                                "USERNAME0003"
                            ) {
                                messageError = "Veuillez saisir une adresse e-mail valide.";
                            }
                            if (
                                result.violations["0"].message.split("|")[0].trim() ===
                                "PASSWORD0001"
                            ) {
                                messageError = "Merci de saisir un mot de passe.";
                            }
                            if (
                                result.violations["0"].message.split("|")[0].trim() ===
                                "PASSWORD0002"
                            ) {
                                messageError = "Merci de saisir un mot de passe.";
                            }
                            if (
                                result.violations["0"].message.split("|")[0].trim() ===
                                "PASSWORD0003"
                            ) {
                                messageError =
                                    "Votre mot de passe doit comporter au moins 5 caractères.";
                            }
                            if (
                                result.violations["0"].message.split("|")[0].trim() ===
                                "PASSWORD0004"
                            ) {
                                messageError =
                                    "Votre mot de passe doit comporter au moins de 45 caractères.";
                            }
                            if (
                                result.violations["0"].message.split("|")[0].trim() ===
                                "USERNAME0006"
                            ) {
                                messageError =
                                    "Cet e-mail est déjà utilisé. Veuillez en saisir un autre ou vous connecter.";
                            }
                            this.setState({
                                password: "",
                                confirmation: "",
                                errorFetchUser: messageError,
                                pending: false,
                            });
                        } else {
                            let data = {
                                username: this.state.username,
                                password: this.state.password,
                            };
                            // Get token
                            fetch(url + "/users/login", {
                                method: "POST",
                                body: JSON.stringify(data),
                                headers: new Headers({
                                    "Content-Type": "application/ld+json",
                                }),
                            }).then((response) => {
                                if (response.status === 400 || response.status === 401) {
                                    this.setState({
                                        password: "",
                                        confirmation: "",
                                        errorFetchUser:
                                            "Cet e-mail est déjà utilisé. Veuillez en saisir un autre ou vous connecter.",
                                        pending: false,
                                    });
                                } else {
                                    response
                                        .json()
                                        .then(function (data) {
                                            return data["hydra:description"];
                                        })
                                        .then((response) => {
                                            fetch(url + "/invitation/join/link/" + this.state.token, {
                                                method: "POST",
                                                body: "{}",
                                                headers: new Headers({
                                                    Authorization: "Bearer " + response,
                                                    "Content-Type": "application/ld+json",
                                                }),
                                            })
                                                .then((response) => response.json())
                                                .then((result) => {
                                                    if (result["status"] === "error") {
                                                        this.setState({
                                                            errorFetchUser: result.parameters.description,
                                                            password: "",
                                                            confirmation: "",
                                                            pending: false,
                                                        });
                                                    } else {
                                                        if (
                                                            version.url !== "v3.1" &&
                                                            result.parameters?.id_membership
                                                        ) {
                                                            fetch(
                                                                `${url}/memberships/${result.parameters?.id_membership}`,
                                                                {
                                                                    method: "PUT",
                                                                    headers: {
                                                                        Authorization: `Bearer ${response}`,
                                                                        "Content-Type": "application/json",
                                                                    },
                                                                    body: JSON.stringify({
                                                                        role: this.state.selectedRole,
                                                                        isRoleCertified: this.state.isCertified
                                                                    }),
                                                                }
                                                            )
                                                                .then((response) => response.json())
                                                                .then((result) => result);
                                                        }
                                                        this.setState({
                                                            receiveOk: true,
                                                            pending: false,
                                                        });
                                                    }
                                                });
                                        });
                                }
                            });
                        }
                    });
            } else {
                this.setState({
                    password: "",
                    confirmation: "",
                    errorFetchUser: "Vos deux mots de passe ne correspondent pas.",
                    pending: false,
                });
            }
        }
    };

    handAlert() {
        if (this.state.errorFetchUser !== "") {
            Swal.fire({
                text: this.state.errorFetchUser,
                imageWidth: 95,
                animation: false,
                imageUrl: "../../img/bad-logo.png",
                confirmButtonText: "Je corrige",
            });
            this.setState({
                message: this.state.errorFetchUser,
                errorFetchUser: "",
            });
        }
    }

    render() {
        if (this.state.status === 200) {
            if (this.state.receiveOk) {
                if (this.state.user.length !== 0 || this.state.account.length !== 0) {
                    let data = {
                        user: this.state.user,
                        account: this.state.account,
                    };
                    window.localStorage.setItem(
                        "accountInvitation",
                        JSON.stringify(data)
                    );
                }
                return (
                    <Redirect
                        push
                        to={`/${this.state.Version.url}/invitation-join-success`}
                    />
                );
            }
            const {user, account} = this.state;
            if (this.state.statusInvitation === 200) {
                return (
                    <div className="master-vd">
                        <div className="card-vd mdl-card mdl-shadow--2dp">
                            <Header
                                title={`${user.first_name} ${user.last_name}`}
                                subtitle="vous invite"
                            />
                            <div
                                className="mdl-card__supporting-text"
                                style={{width: "100%"}}
                            >
                                {this.handAlert()}
                                <span className="error">
                  <strong> {this.state.message} </strong>{" "}
                </span>
                                <h5>
                                    Rejoignez Bookinou, la conteuse d'histoires avec la voix des
                                    proches qui donne le goût aux livres.
                                </h5>
                                Vous avez été invité par{" "}
                                <strong>
                                    {user.first_name} {user.last_name}
                                </strong>{" "}
                                à rejoindre sa bibliothèque "<strong>{account.name}</strong>"
                                <form id="join-form-vd" onSubmit={this.handleSubmit}>
                                    <div className="mdl-textfield mdl-js-textfield">
                                        <input
                                            className="mdl-textfield__input"
                                            type="text"
                                            value={this.state.firstName}
                                            name="firstName"
                                            onChange={this.handleChange}
                                            required
                                        />
                                        <label className="mdl-textfield__label" htmlFor="firstName">
                                            {this.state.firstName === "" ? "Votre prénom" : ""}
                                        </label>
                                    </div>
                                    <div className="mdl-textfield mdl-js-textfield">
                                        <input
                                            className="mdl-textfield__input"
                                            value={this.state.lastName}
                                            type="text"
                                            name="lastName"
                                            onChange={this.handleChange}
                                            required
                                        />
                                        <label className="mdl-textfield__label" htmlFor="lastName">
                                            {this.state.lastName === "" ? "Votre nom" : ""}
                                        </label>
                                    </div>
                                    <div className="mdl-textfield mdl-js-textfield">
                                        <input
                                            className="mdl-textfield__input"
                                            value={this.state.username}
                                            type="email"
                                            name="username"
                                            onChange={this.handleChange}
                                            required
                                        />
                                        <label className="mdl-textfield__label" htmlFor="username">
                                            {this.state.username === "" ? "Votre e-mail" : ""}
                                        </label>
                                    </div>
                                    <div className="mdl-textfield mdl-js-textfield">
                                        <input
                                            className="mdl-textfield__input"
                                            value={this.state.password}
                                            type="password"
                                            name="password"
                                            onChange={this.handleChange}
                                            required
                                        />
                                        <label className="mdl-textfield__label" htmlFor="password">
                                            {this.state.password === "" ? "Votre mot de passe" : ""}
                                        </label>
                                    </div>
                                    <div className="mdl-textfield mdl-js-textfield">
                                        <input
                                            className="mdl-textfield__input"
                                            value={this.state.confirmation}
                                            type="password"
                                            name="confirmation"
                                            onChange={this.handleChange}
                                            required
                                        />
                                        <label className="mdl-textfield__label" htmlFor="password2">
                                            {this.state.confirmation === ""
                                                ? " Confirmez votre mot de passe"
                                                : ""}
                                        </label>
                                    </div>
                                    {this.state.roles && this.state.roles.length > 0 && (
                                        <RoleSelect
                                            roles={this.state.roles}
                                            selectedRole={this.state.selectedRole}
                                            handleRoleChange={this.handleRoleChange}
                                        />
                                    )}

                                    {['École', 'Médiathèque'].includes(this.state.selectedRole) && (
                                        <div className="mdl-textfield mdl-js-textfield">
                                            <label className="mdl-checkbox mdl-js-checkbox" htmlFor="school-checkbox">
                                                <input type="checkbox" id="school-checkbox"
                                                       className="mdl-checkbox__input"
                                                       required={true}
                                                       onClick={() => {
                                                           this.setState({isCertified: !this.state.isCertified})
                                                       }} checked={this.state.isCertified}/>
                                                <span className="mdl-checkbox__label">Je certifie sur l'honneur que je représente bien {this.state.selectedRole === "École" ? ' une école ou une classe.' : ' une médiathèque'} </span>
                                            </label>
                                        </div>
                                    )}
                                    <hr/>
                                    <div id="button-container">
                                        <NavLink
                                            style={{
                                                backgroundColor: "#00818d",
                                                textDecoration: "none",
                                                color: "white",
                                                fontSize: "15px",
                                                padding: 5,
                                                marginTop: 0,
                                                borderRadius: "2px",
                                                fontFamily: "Quicksand",
                                            }}
                                            exact
                                            to={
                                                "/" +
                                                this.state.Version.url +
                                                "/invitation/login/" +
                                                this.state.token
                                            }
                                        >
                                            DÉJÀ UN COMPTE
                                        </NavLink>
                                        <Button
                                            label="SUIVANT"
                                            type="submit"
                                            isLoading={this.state.pending}
                                        />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                );
            }
            if (this.state.statusInvitation === 404) {
                return (
                    <div className="master-vd">
                        <div className="card-vd mdl-card mdl-shadow--2dp">
                            <Header title="Invitation"/>
                            <div className="mdl-card__supporting-text">
                                <div className="errorNotFound">
                                    <h5>Page invalide</h5>
                                    {this.handAlert()}
                                    <span className="error">
                    <strong> {this.state.message} </strong>{" "}
                  </span>
                                </div>
                            </div>
                        </div>
                    </div>
                )
                    ;
            }
            if (this.state.statusInvitation === 0) {
                return <LoadingCard title="Invitation" description="Chargement"/>;
            }
        }
        if (this.state.status === 404) {
            return <NotFound/>;
        }
        if (this.state.status === 0) {
            return <LoadingCard title="Invitation" description="Chargement"/>;
        }
    }
}

export default Invitation;
