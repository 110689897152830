import React, { Component } from "react";
import "./PasswordForgot.css";
import "../../../node_modules/material-design-lite/material.min.css";
import "material-design-lite/material.min";
import NotFound from "../NotFound/NotFound";
import Header from "../../components/Header/Header";

class SuccessReset extends Component {
  state = {
    descriptions: this.props.match.params.description,
  };

  render() {
    let description = JSON.parse(
      window.localStorage.getItem("successPasswordForgotChange")
    );
    window.localStorage.clear();
    if (description !== null) {
      return (
        <div className="master-vd">
          <div className="card-vd mdl-card mdl-shadow--2dp">
            <Header title={description.title} />
            <div className="mdl-card__supporting-text">
              <div className="successPasswordForgot">
                <h5>{description.subtitle}</h5>
                <span>{description.description}</span>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return <NotFound />;
    }
  }
}

export default SuccessReset;
