import React from "react";
import { Ring } from "@uiball/loaders";
const Button = ({ label, onClickAction, type, width, fontSize, isLoading }) => {
  const handleOnClick = () => {
    if (onClickAction) {
      onClickAction();
    }
  };

  return (
    <button
      onClick={handleOnClick}
      type={type}
      disabled={isLoading}
      style={{
        backgroundColor: "#00818d",
        fontSize: fontSize || "15px",
        color: isLoading && "black",
        padding: "5px 15px",
        marginTop: 0,
        textAlign: "center",
        width: width || "fit-content",
        fontFamily: "Quicksand",
      }}
    >
      {!isLoading ? label : <Ring size={24} speed={2} color="#FFFFFF" />}
    </button>
  );
};

export default Button;
