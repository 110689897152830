import React from "react";

const RoleSelect = ({roles, selectedRole, handleRoleChange}) => {
    return (
        <div className="mdl-selectfield mdl-js-selectfield">
            {!selectedRole && (
                <label className="mdl-selectfield__label" htmlFor="myselect">
                    Votre role
                </label>
            )}
            <select
                id="roleSelect"
                name="roleSelect"
                className="mdl-selectfield__select mdl-textfield__input"
                style={{padding: 8}}
                onChange={(event) => {
                    handleRoleChange(event.target.value);
                }}
                required
            >
                <option value=""></option>
                {roles.map((role, index) => (
                    <option value={role.value} key={index}>
                        {role.value}
                    </option>
                ))}
            </select>

        </div>
    );
};

export default RoleSelect;
