import React, { Component } from "react";
import "./Login.css";
import "../../../node_modules/material-design-lite/material.min.css";
import "material-design-lite/material.min";

class JoinError extends Component {
  state = {
    detail: this.props.match.params.detail,
  };

  render() {
    return (
      <div className="master-vd">
        <div className="card-vd mdl-card mdl-shadow--2dp">
          <div className="header-vd mdl-card__title">
            <h2 className="mdl-card__title-text">Rejoingez le Bookinou</h2>
          </div>
          <div className="mdl-card__supporting-text">
            <diV className="error">
              <span>
                {" "}
                <strong> {this.state.detail}</strong>
              </span>
            </diV>
          </div>
        </div>
      </div>
    );
  }
}

export default JoinError;
