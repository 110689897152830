import React, { Component } from "react";
import "./Stories.css";
import StoryCard from "../../components/StoryCard/StoryCard";
import { Settings, Version } from "../../Settings";
import "../../../node_modules/material-design-lite/material.min.css";
import "material-design-lite/material.min";
import NotFound from "../NotFound/NotFound";

class Stories extends Component {
  state = {
    usertoken: this.props.match.params.usertoken,
    id_membership: this.props.match.params.id_membership,
    listStoriesID: [],
    stories: {},
    str: "",
    dataStories: [],
    Settings,
    Version,
  };

  componentDidMount() {
    let url = this.state.Settings.url;
    //Get stories from API
    let data = {};
    data["query"] = "{stories {edges{node { id title } } } }";
    fetch(url + "/graphql", {
      method: "POST",
      body: JSON.stringify({
        query:
          "{ stories { edges { node { id title picture { contentUrl } } }  pageInfo{ hasNextPage endCursor } totalCount } }",
      }),
      headers: new Headers({
        Authorization: "Bearer " + this.state.usertoken,
        "Content-Type": "application/json",
      }),
    })
      .then((response) => response.json())
      .then((resultat) => {
        if (resultat.hasOwnProperty("data")) {
          if (resultat.data.stories !== null) {
            if (resultat.data.stories.totalCount === 0) {
              window.location.replace("/" + Version.url + "/stories/success");
            }
            this.setState({
              stories: resultat.data.stories.edges,
            });
          } else {
            this.setState({
              stories: "",
            });
            window.location.replace("/" + Version.url + "/stories/success");
          }
        } else {
          this.setState({
            stories: "",
          });
          window.location.replace("/" + Version.url + "/stories/success");
        }
      });
  }

  add = (details) => {
    if (this.state.dataStories.indexOf(details) === -1) {
      this.state.dataStories.push(details);
    } else {
      this.state.dataStories.splice(this.state.dataStories.indexOf(details), 1);
    }
  };
  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ sendTo: true });
    let url = this.state.Settings.url;
    let version = this.state.Version.url;
    // Get token
    // Get token
    let dataMembership = this.state.id_membership;
    let dataUserToken = this.state.usertoken;
    this.state.dataStories.forEach(function (element) {
      let data = {
        story: element,
        membership: "/api/memberships/" + dataMembership,
      };
      fetch(url + "/libraries", {
        method: "POST",
        body: JSON.stringify(data),
        headers: new Headers({
          Authorization: "Bearer " + dataUserToken,
          "Content-Type": "application/json",
        }),
      })
        .then((response) => response.json())
        .then((result) => {
          window.location.replace("/" + version + "/stories/success");
        });
    });
  };

  render() {
    const storiesList = Object.keys(this.state.stories).map((key) => (
      <StoryCard
        key={key}
        details={this.state.stories[key].node.id}
        index={key}
        image={this.state.stories[key].node.picture.contentUrl}
        titreStory={this.state.stories[key].node.title}
        add={this.add}
      />
    ));
    if (this.state.stories !== "") {
      return (
        <div className="master-vd">
          <div className="card-vd mdl-card mdl-shadow--2dp">
            <div className="header-vd mdl-card__title">
              <h2 className="mdl-card__title-text">Mes histoires</h2>
            </div>
            <div>
              <div className="mdl-grid" id="stories-grid">
                {storiesList}
              </div>
              <form className="join-form" onSubmit={this.handleSubmit}>
                <div className="button-vd mdl-card__actions mdl-card--border">
                  <button
                    className="next-vd mdl-button mdl-button--colored mdl-js-button mdl-js-ripple-effect"
                    type="submit"
                  >
                    SUIVANT
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      );
    } else {
      return <NotFound />;
    }
  }
}

export default Stories;
