import Header from "./Header/Header";
import React from "react";

const LoadingCard = ({ title, subtitle, description }) => {
  return (
    <div className="master-vd">
      <div className="card-vd mdl-card mdl-shadow--2dp">
        <Header title={title} subtitle={subtitle} />
        <div className="mdl-card__supporting-text">
          <div className="errorNotFound">
            <h5>{description}</h5>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoadingCard;
