import React from "react";
import "./NotFound.css";
import "../../../node_modules/material-design-lite/material.min.css";
import "material-design-lite/material.min";
import Header from "../../components/Header/Header";

const NotFound = () => {
  return (
    <div className="master-vd not-found-container">
      <Header title="Bookinou" />
      <div className="mdl-card__supporting-text">
        <div className="errorNotFound">
          <h5>Page inconnue</h5>
          <span>
            Êtes-vous perdu ? Un souci ? N'hésitez pas à consulter notre{" "}
            <a href="https://www.mybookinou.com/faq-9">FAQ</a> pour vous aider,
            ou nous contacter à l'adresse :{" "}
          </span>
          <br />
          <br />
        </div>
        <div className="hello">
          <span>
            <a href="mailto:help@mybookinou.com">help@mybookinou.com</a>
          </span>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
