import React, { Component } from "react";
import "./PasswordForgot.css";
import { NavLink } from "react-router-dom";
import { Settings, Version } from "../../Settings";
import "../../../node_modules/material-design-lite/material.min.css";
import "material-design-lite/material.min";
import Header from "../../components/Header/Header";

class PasswordForgotCheck extends Component {
  state = {
    answere: "",
    token: this.props.match.params.token,
    Settings,
    Version,
    description: [],
    status: 0,
    statusCheck: 0,
  };

  componentWillMount() {
    let url = this.state.Settings.url;
    fetch(url + "/users/me", {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/ld+json",
      }),
    })
      .then((response) => {
        fetch(url + "/users/password/forgot/check/" + this.state.token, {
          method: "POST",
          body: {},
          headers: new Headers({
            "Content-Type": "application/ld+json",
          }),
        })
          .then((response) => response.json())
          .then((result) => {
            if (result.status === "error") {
              this.setState({
                answere: result.parameters.description,
                description: result.parameters,
                statusCheck: 404,
              });
            } else {
              if (result.status === "success") {
                window.location.replace(
                  "/" +
                    this.state.Version.url +
                    "/password-forgot-change/" +
                    this.state.token
                );
              } else {
                this.setState({
                  answere: result.status,
                  statusCheck: 200,
                });
              }
            }
          });
        this.setState({
          status: 200,
        });
      })
      .catch((error) => {
        this.setState({
          status: 404,
        });
      });
  }

  render() {
    if (this.state.status === 200) {
      if (this.state.statusCheck === 404) {
        let message = this.state.description;
        return (
          <div className="master-vd">
            <div className="card-vd mdl-card mdl-shadow--2dp">
              <div className="header-vd mdl-card__title">
                <h2 className="mdl-card__title-text">{message.title}</h2>
              </div>
              <div className="mdl-card__supporting-text">
                <div className="successPasswordForgot">
                  <h5>{message.subtitle}</h5>
                  <span>
                    <strong>{message.description}</strong>
                  </span>
                </div>
              </div>
            </div>
          </div>
        );
      } else {
        return (
          <div className="master-vd">
            <div className="card-vd mdl-card mdl-shadow--2dp">
              <Header subtitle="Récupération du mot de passe" />
              <div className="mdl-card__supporting-text">
                <div className="errorNotFound">
                  <h5>Chargement</h5>
                </div>
              </div>
            </div>
          </div>
        );
      }
    }
    if (this.state.status === 404) {
      return (
        <div className="master-vd">
          <div className="card-vd mdl-card mdl-shadow--2dp">
            <Header subtitle="Récupération du mot de passe" />
            <div className="mdl-card__supporting-text">
              <div className="errorNotFound">
                <h5>Page invalide</h5>
                <span className="error">
                  Cette page est invalide. Veuillez refaire votre demande de
                  récupération de Mot de passe en cliquant sur ce lien
                  <NavLink
                    className="mdl-button mdl-button--colored mdl-js-button mdl-js-ripple-effect"
                    exact
                    to={"/" + this.state.Version.url + "/password-forgot"}
                  >
                    RÉCUPERER MON MOT DE PASSE
                  </NavLink>
                </span>
              </div>
            </div>
          </div>
        </div>
      );
    }
    if (this.state.status === 0) {
      return (
        <div className="master-vd">
          <div className="card-vd mdl-card mdl-shadow--2dp">
            <Header subtitle="Récupération du mot de passe" />
            <div className="mdl-card__supporting-text">
              <div className="errorNotFound">
                <h5>Chargement</h5>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default PasswordForgotCheck;
