import React, {Component} from 'react'
import './Error.css'
import "../../../node_modules/material-design-lite/material.min.css"
import "../../../node_modules/material-design-lite/material.min.js"
class Error extends Component {
    state = {
        detail: this.props.match.params.details
    }

    render() {

        return (
            <div className="master-vd">
                <div className="card-vd mdl-card mdl-shadow--2dp">
                    <div className="header-vd mdl-card__title">
                        <h2 className="mdl-card__title-text">
                           Bookinou
                        </h2>
                    </div>
                    <div className="mdl-card__supporting-text">
                        <diV  className='join-form-vd'>
                            <span className="error"><strong> {this.state.detail}</strong></span>
                        </diV>
                     </div>
                </div>
            </div>
        )
    }
}

export default Error
