import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import PasswordForgot from "./pages/PasswordForgot";
import ChangePassword from "./pages/ChangePassword";
import PasswordForgotCheck from "./pages/PasswordForgot/PasswordForgotCheck";
import Success from "./pages/PasswordForgot/Success";
import SuccessReset from "./pages/PasswordForgot/SuccessReset";
import ResetPasswordSuccess from "./pages/ChangePassword/ResetPasswordSuccess";
import PasswordForgotChange from "./pages/PasswordForgotChange";
import NotFound from "./pages/NotFound/NotFound";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Invitation from "./pages/Invitation/Invitation";
import SuccessInvitation from "./pages/Invitation/SuccessInvitation";
import Login from "./pages/Login/Login";
import Stories from "./pages/Stories";
import JoinError from "./pages/Login/JoinError";

import Error from "./components/Error";

import version from "./Settings/Version";

const Root = () => (
  <BrowserRouter>
    <Switch>
      <Route
        path={"/" + version.url + "/password-forgot"}
        component={PasswordForgot}
      />
      <Route
        path={"/" + version.url + "/password-forgot-send"}
        component={Success}
      />
      <Route
        path={"/" + version.url + "/password-forgot-check/:token"}
        component={PasswordForgotCheck}
      />
      <Route
        path={"/" + version.url + "/password-forgot-change/:token"}
        component={PasswordForgotChange}
      />
      <Route
        path={"/" + version.url + "/password-forgot-success"}
        component={SuccessReset}
      />
      <Route
        path={"/" + version.url + "/password-reset/:token"}
        component={ChangePassword}
      />
      <Route
        path={"/" + version.url + "/password-reset-success"}
        component={ResetPasswordSuccess}
      />
      <Route
        path={"/" + version.url + "/invitation/login/:token"}
        component={Login}
      />
      <Route
        path={"/" + version.url + "/invitation-join/:token"}
        component={Invitation}
      />
      <Route
        path={"/" + version.url + "/invitation-join-success"}
        component={SuccessInvitation}
      />
      <Route
        path={"/" + version.url + "/stories/:usertoken/:id_membership"}
        component={Stories}
      />
      <Route
        path={"/" + version.url + "/join/error/:detail"}
        component={JoinError}
      />
      <Route
        path={"/" + version.url + "/stories/success"}
        component={SuccessInvitation}
      />
      <Route
        path={"/" + version.url + "/login/success"}
        component={SuccessInvitation}
      />
      <Route path={"/" + version.url + "/error/:details"} component={Error} />
      <Route exac component={NotFound} />
    </Switch>
  </BrowserRouter>
);
ReactDOM.render(<Root />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
